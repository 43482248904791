import { Injectable } from '@angular/core';
import { dbConst } from './databaseConstants';
import { FirestoreCrudService } from './crudService';
import { AuthService } from '../auth.service';
import { Firestore } from '@angular/fire/firestore';
import { IPlaato2 } from 'app/models/plaato2';
import { v4 as uuid } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class Plaato2Service {
  private crudService: FirestoreCrudService<IPlaato2>;
  constructor(
    private firestore: Firestore,
    private authService: AuthService) {
    this.crudService = new FirestoreCrudService<IPlaato2>(firestore, authService, dbConst.plaato2);
  }

  public triggerActive = false;

  public getNewId() {
    return this.crudService.getNewId();
  }

  async add(item: IPlaato2, id: string = "") {
    if (id === "") {
      id = this.crudService.getNewId();
    }
    const newItem = await this.crudService.add(item, id);
    return newItem;
  }

  async update(item: IPlaato2) {
    return await this.crudService.update(item);
  }

  async delete(id: string) {
    if (id)
      await this.crudService.delete(id);
  }

  getItem(id: string) {
    return this.crudService.get(id)
  }

  getItems(barId: string) {
    console.log('getItems barId', barId);

    return this.crudService.getItems(barId);
  }

  getItemsOnce(barId: string) {
    return this.crudService.getItemsOnce(barId, "name", 100);
  }

  getItemsPromise(barId: string) {
    return this.crudService.getItemsOnce(barId, "name", 100);
  }

  async softUpdate(id: string, item: any, uid: string) {
    return await this.crudService.softUpdate(id, item, uid);
  }

}
