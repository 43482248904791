import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as Actions from './actions'
import { IPlaato2 } from 'app/models/plaato2';
import { inject } from '@angular/core';
import { Router } from '@angular/router';

export interface State extends EntityState<IPlaato2> {
  // additional entity state properties
  selectedId: string | null;
  error: string;
}


export function sortByName(a: IPlaato2, b: IPlaato2): number {
  return a.name.localeCompare(b.name);
}
export function selectId(a: IPlaato2): string {
  return a.id ?? '';
}
export const adapter: EntityAdapter<IPlaato2> = createEntityAdapter<IPlaato2>({
  sortComparer: sortByName,
  selectId: selectId
});

export const initialState: State = adapter.getInitialState({
  selectedId: null,
  error: ''
});

export const stateName = 'plaato2';

export const reducer = createReducer<State>(
  initialState,
  on(Actions.clearAllItems, (state) => {
    return adapter.removeAll(state);
  }),

  on(Actions.loadItemsSuccess, (state, { items }) => {
    console.log('plaato2 reducer items', items);

    return adapter.setAll(items, state);
  }),

  on(Actions.loadItemsFailure, (state, { error }) => {
    console.log('load item failuer error', error);

    return { ...state, error: error };
  }),


  on(Actions.setItem, (state, action) => {
    return Object.assign({ ...state, selectedId: action.id });
  })


);

// get the selectors
const { selectAll, selectEntities } = adapter.getSelectors();

const selectState = createFeatureSelector<State>(stateName);
export const getSelectedId = (state: State) => state.selectedId;
export const getState = createFeatureSelector<State>(stateName);
export const selectAllItems = createSelector(getState, selectAll);
export const selectAllEntities = createSelector(getState, selectEntities);
export const getCurrentId = createSelector(getState, getSelectedId);
export const selectCurrentItem = createSelector(selectAllEntities, getCurrentId, (entities, id) => {
  if (id) return entities[id];
  return null;
});
export const selectEntity = (id: string) => createSelector(selectAllEntities, entities => entities[id]);

