import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, from } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import * as Action from './actions'
import { Plaato2Service } from 'app/services/dal/plaato2.service';
import { Router } from '@angular/router';
import { ToastService } from '../../services/toast.service';



@Injectable()
export class Effects {

  constructor(private toastService: ToastService, private router: Router, private actions$: Actions, private service: Plaato2Service) { }

  gets$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(Action.loadItems),
        mergeMap((action) => this.service.getItems(action.barId)
          .pipe(
            map(items => Action.loadItemsSuccess({ items: items })),
            catchError(error => of(Action.loadItemsFailure({ error })))
          )
        )
      );
  });




  setItemFromListPage$ = createEffect(
    () => {
      return this.actions$
        .pipe(
          ofType(Action.setItemFromListPage),
          tap((action) => {
            this.router.navigate(['/settings/plaato2/detail', action.id]);
          }),
        )
    },
    { dispatch: false }
  );


  update$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(Action.updateItem),
        mergeMap((action) => of(this.service.update(action.item))
          .pipe(
            map(() => {
              this.toastService.saveSuccess();
              return Action.updateItemSuccess({ item: action.item });
            }),
            catchError(error => of(Action.updateItemFailure({ error })))
          )
        )
      );
  });



  add$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(Action.addItem),
        mergeMap((action) => of(this.service.add(action.item, action.id))
          .pipe(
            map(() => {
              this.toastService.saveSuccess();
              return Action.addItemSuccess({ item: action.item });
            }),
            catchError(error => of(Action.addItemFailure({ error })))
          )
        )
      );
  });


  adddSuccess$ = createEffect(
    () => {
      return this.actions$
        .pipe(
          ofType(Action.addItemSuccess),
          tap(({ item }) => {
            this.router.navigate(['/settings/plaato2/detail', item.id]);
          }),
        )
    },
    { dispatch: false }
  );




  delete$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(Action.deleteItem),
        mergeMap((action) => of(this.service.delete(action.id))
          .pipe(
            map(() => {
              this.toastService.deleteItemSuccess();
              return Action.deleteItemSuccess();
            }),
            catchError(error => of(Action.addItemFailure({ error })))
          )
        )
      );
  });



  deleteSuccess$ = createEffect(
    () => {
      return this.actions$
        .pipe(
          ofType(Action.deleteItemSuccess),
          tap(() => {
            this.router.navigate(['/settings/plaato2/']);
          }),
        )
    },
    { dispatch: false }
  );


  softUpdate$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(Action.softUpdate),
        mergeMap((action) => from(this.service.softUpdate(action.id, action.data, action.uid))
          .pipe(
            map(() => {
              this.toastService.saveSuccess();
              return Action.softUpdateSuccess({ id: action.id, data: action.data })
            }),
            catchError((error) => {
              return of(Action.softUpdateFailure({ error }))
            })
          )
        )
      );
  });

  softUpdateSuccess$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(Action.softUpdateSuccess),
        tap(() => {
          this.toastService.saveSuccess();
        }),
      );
  }, { dispatch: false });


  softUpdateFailure$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(Action.softUpdateFailure),
        tap(({ error }) => {
          this.toastService.error(JSON.stringify(error));
        })
      );
  }, { dispatch: false });





}
