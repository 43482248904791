import { Injectable } from '@angular/core';
import { IPlaato } from '../../models/plaato';
import { dbConst } from './databaseConstants';
import { FirestoreCrudService } from './crudService';
import { AuthService } from '../auth.service';
import { Firestore, collection, collectionData, orderBy, query } from '@angular/fire/firestore';
import { where, OrderByDirection } from 'firebase/firestore';
import { take, tap } from 'rxjs';
import { TapService } from './tap.service';
import { ITap } from 'app/models/tap';

@Injectable({
  providedIn: 'root'
})

export class PlaatoService {
  private crudService: FirestoreCrudService<IPlaato>;
  constructor(
    private firestore: Firestore,
    private tapService: TapService,
    private authService: AuthService) {
    this.crudService = new FirestoreCrudService<IPlaato>(firestore, authService, dbConst.plaatoKegs);
  }

  public triggerActive = false;

  getNewId(): string {
    return this.crudService.getNewId();
  }

  async add(item: IPlaato) {
    if (!item.id) {
      item.id = undefined;
    }

    const newItem = await this.crudService.add(item, item.id);
    return newItem;
  }

  async update(item: IPlaato) {
    return await this.crudService.update(item);
  }

  async delete(id: string) {
    //Delete all plaato data from taps
    const listRef = collection(this.firestore, dbConst.taps);
    const q = query(listRef, where("plaatoId", "==", id));
    collectionData(q, { idField: 'id' }).pipe(
      take(1),
      tap((taps: any[]) => {
        taps.map((tap: any) => {
          if (tap.id) {
            const tapUpdate: Partial<ITap> = {
              plaatoId: '',
            }
            this.tapService.softUpdate(tap.id, tapUpdate, tap.uBy ?? '');
          }
        })
      })).subscribe();




    await this.crudService.delete(id);
  }

  getItem(id: string) {
    return this.crudService.get(id)
  }

  getItems(barId: string) {
    return this.crudService.getItemsOrderBy(barId, 'name', 'asc', 1000);
  }

  getItemsOnce(barId: string) {
    return this.crudService.getItemsOnce(barId, "name", 100);
  }

  getItemsPromise(barId: string) {
    return this.crudService.getItemsOnce(barId, "name", 100);
  }



}
