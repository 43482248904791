import { createAction, props } from '@ngrx/store';
import { IPlaato2 } from 'app/models/plaato2';

export const clearAllItems = createAction('[DalHelper] Clear All Plaato2');
export const loadItems = createAction('[App Plaato2 List Page] Load Plaato2', props<{ barId: string }>());
export const loadItemsSuccess = createAction('[Plaato2 API] Load Plaato2 Success', props<{ items: IPlaato2[] }>());
export const loadItemsFailure = createAction('[Plaato2 API] Load Plaato2 Failure', props<{ error: any }>());

export const setItemFromListPage = createAction('[Plaato2 List Page] Set Selected Item', props<{ id: string }>());
export const setItem = createAction('[Plaato2 Page] Set Selected Item', props<{ id: string }>());
export const addItem = createAction('[Plaato2 Detail Page] Add Plaato2', props<{ id: string, item: IPlaato2 }>());
export const addItemSuccess = createAction('[Plaato2 API] Add Plaato2 Success', props<{ item: IPlaato2 }>());
export const addItemFailure = createAction('[Plaato2 API] Add Plaato2 Failure', props<{ error: any }>());

export const deleteItem = createAction('[Plaato2 Detail Page] Delete Plaato2', props<{ id: string }>());
export const deleteItemSuccess = createAction('[Plaato2 API] Delete Plaato2 Success');
export const deleteItemFailure = createAction('[Plaato2 API] Delete Plaato2 Failure', props<{ error: any }>());

export const softUpdate = createAction('[Plaato2 API] Soft Update Plaato2', props<{ id: string, data: any, uid: string }>());
export const softUpdateSuccess = createAction('[Plaato2 API] Soft Update Plaato2 Success', props<{ id: string, data: any }>());
export const softUpdateFailure = createAction('[Plaato2 API] Soft Update Plaato2 Failure', props<{ error: any }>());

export const updateItem = createAction('[Plaato2 Detail Page] Update Plaato2', props<{ item: IPlaato2 }>());
export const updateItemSuccess = createAction('[Plaato2 API] Update Plaato2 Success', props<{ item: IPlaato2 }>());
export const updateItemFailure = createAction('[Plaato2 API] Update Plaato2 Failure', props<{ error: any }>());
